<template>
  <div v-if="page.length">
    <div class="relative">
      <img
        :src="isMobile ? page[0].img_m : page[0].img"
        class="width-100"
        alt=""
      >
      <div
        v-if="isMobile"
        class="f32 absolute b40 l30 c-fff b"
        v-html="page[0].t1.split(' ').join('<br>')"
        v-vue-aos.once="{animationClass:'fadeInUp animated'}"
        data-aos="fade-right"
      />
      <div
        v-else
        class="absolute-center f40 c-fff b"
      >
        {{ page[0].t1 }}
      </div>
    </div>

    <div
      class="bg-f7"
      :style="isMobile ? 'background-color:#F1F9FE' : 'padding: 100px'"
      :class="isMobile ? 'ptb40 plr20' : 'flex-center'"
    >
      <div
        class="flex-grow-1"
        :style="isMobile ? '' : 'margin-right:130px'"
      >
        <div
          class="bd bb bd-000 mb20 pb20"
          :class="isMobile ? 'f28' : 'f40'"
          v-vue-aos.once="{animationClass:'fadeInUp animated'}"
          data-aos="fade-left"
        >
          {{ page[1].t1 }}
        </div>
        <div
          class="f18"
          style="font-weight:lighter;line-height:30px;"
          v-vue-aos.once="{animationClass:'fadeInUp animated'}"
          data-aos="fade-left"
        >
          {{ page[1].t2 }}
        </div>
        <img
          v-if="isMobile"
          :src="page[1].img_m"
          alt=""
          class="width-100 mt30"
        >
        <div
          class="flex-center-between width-100"
          :style="isMobile ? 'margin-top:30px;' : 'margin-top:80px;'"
        >
          <div
            v-for="item in page[1].t3"
            :key="item.y"
            class="f14"
            v-vue-aos.once="{animationClass:'fadeInUp animated',animationstart:onAnimationstart}"
            data-aos="fade-left"
          >
            <span
              style="font-size:38px;color:#ed7989;"
              :style="isMobile && 'font-size:20px;'"
              v-vue-aos.once="{animationClass:'fadeInUp animated'}"
              data-aos="fade-left"
            >
              <count-to
                ref="count"
                :start-val="0"
                :end-val="parseInt(item.y)"
                :duration="2000"
                :autoplay="false"
              />
            </span>
            <span
              v-vue-aos.once="{animationClass:'fadeInUp animated'}"
              data-aos="fade-left"
            >{{ item.u }}</span>
            <div
              class="f20"
              :style="isMobile && 'font-size:14px;'"
              v-vue-aos.once="{animationClass:'fadeInUp animated'}"
              data-aos="fade-right"
            >
              {{ item.v }}
            </div>
          </div>
        </div>

<!--        <div style="margin-top: 30px; color: #aaa;"-->
<!--             :style="isMobile ? 'font-size: 12px; ' : 'font-size: 13px; '"-->
<!--        >{{ page[1].tips }}</div>-->
      </div>
      <img
        v-if="!isMobile"
        class="flex-shrink-0"
        :src="page[1].img"
        :style="'width:644px;'"
        v-vue-aos.once="{animationClass:'fadeInUp animated'}"
        data-aos="fade-right"
      >
    </div>

<!--  展会  -->
    <div class="tc" style="padding: 0 0 80px 0;">
      <div
        class="relative z1"
        :style="isMobile ? 'font-size:24px;padding: 50px 0 0px 0; width:75%; text-align:center; margin:0 auto; ' : 'font-size:40px;padding: 80px 0 10px 0;'"
        v-vue-aos.once="{animationClass:'fadeInUp animated'}"
        data-aos="fade-up"
      >
        {{ page[7].t1 }}
      </div>
      <div
        class="dib relative z1"
        style="width: 30px;height: 2px;background: #ed7989;"
        v-vue-aos.once="{animationClass:'fadeInUp animated'}"
        data-aos="fade-up"
      />
      <div style="margin-top: 30px;">
        <VideoPlayer
          v-show="true"
          :videoUrl="page[7].video"
          :videoCover="page[7].img"
          :width="isMobile ? '90%' : '1000'"
          :height="isMobile ? 'auto' : '550'"
          :autoplay="false"
          :controls="true"
          :loop="false"
          :muted="false"
          preload="auto"
          :showPlay="true"
          :playWidth="96"
          zoom="cotain"
          v-vue-aos.once="{animationClass:'fadeInUp animated'}"
          data-aos="fade-up"
        />
      </div>
    </div>
<!--  节目  -->
    <div class="tc"
         :style="isMobile ? 'background:#F1F9FE' : 'background:#FAFCFF'"
         style="padding: 0 0 80px 0;">
      <div
        class="relative z1"
        :style="isMobile ? 'font-size:24px;padding: 30px 0 0px 0; width:75%; text-align:center; margin:0 auto; ' : 'font-size:40px;padding: 80px 0 10px 0;'"
        v-vue-aos.once="{animationClass:'fadeInUp animated'}"
        data-aos="fade-up"
      >
        {{ page[8].t1 }}
      </div>
      <div
        class="dib relative z1"
        style="width: 30px;height: 2px;background: #ed7989;"
        v-vue-aos.once="{animationClass:'fadeInUp animated'}"
        data-aos="fade-up"
      />
      <div style="margin-top: 30px;">
        <VideoPlayer
          v-show="true"
          :videoUrl="page[8].video"
          :videoCover="page[8].img"
          :width="isMobile ? '90%' : '1000'"
          :height="isMobile ? 'auto' : '550'"
          :autoplay="false"
          :controls="false"
          :loop="false"
          :muted="false"
          preload="auto"
          :showPlay="true"
          :playWidth="96"
          zoom="cotain"
          v-vue-aos.once="{animationClass:'fadeInUp animated'}"
          data-aos="fade-up"
        />
      </div>
    </div>

<!--  会员单位  -->
    <div
      :style="isMobile ? 'padding: 0 0 100px 0; ' : 'padding: 100px 0; '"
    >
      <div style="margin: 0 auto; text-align: center;"
           :style="isMobile ? 'width: 98%; ' : 'width: 75%; '"
      >
        <div
          class="relative z1"
          :style="isMobile ? 'font-size:24px;padding: 30px 0 0px 0; width:75%; text-align:center; margin:0 auto; ' : 'font-size:40px;padding: 80px 0 10px 0;'"
          v-vue-aos.once="{animationClass:'fadeInUp animated'}"
          data-aos="fade-up"
        >
          {{ page[9].t1 }}
        </div>
        <div
          class="dib relative z1"
          style="width: 30px;height: 2px;background: #ed7989;"
          v-vue-aos.once="{animationClass:'fadeInUp animated'}"
          data-aos="fade-up"
        />
        <div style="text-align: center; margin-top: 30px; "
             v-vue-aos.once="{animationClass:'fadeInUp animated'}"
             data-aos="fade-up"
        >
          <img :src="page[9].img"
               :style="isMobile ? 'width: 90%; ' : 'width: 50%'">
        </div>
      </div>
    </div>

    <!--    企业愿景    -->
    <div class="tc"
         :style="isMobile ? 'background:#F1F9FE' : 'background:#FAFCFF'"
    >
      <div
        class="relative z1"
        :style="isMobile ? 'font-size:24px;padding: 30px 0 0px 0;' : 'font-size:40px;padding: 80px 0 10px 0;'"
        v-vue-aos.once="{animationClass:'fadeInUp animated'}"
        data-aos="fade-up"
      >
        {{ page[2].t1 }}
      </div>
      <div
        class="dib relative z1"
        style="width: 30px;height: 2px;background: #ed7989;"
      />
      <div :class="isMobile ? 'plr20' : 'flex-center ptb50'">
        <div
          v-for="(item,i) in page[2].d"
          :key="i"
          :style="isMobile ? 'margin:20px 0;' : 'width:320px;margin:0 26px;'"
          class="relative"
          v-vue-aos.once="{animationClass:'fadeInUp animated'}"
          data-aos="fade-down"
          data-aos-duration="1000"
        >
          <img
            :src="isMobile ? item.img_m : item.img"
            alt=""
            class="width-100"
          >
          <div
            class="absolute l20 r20 c-fff tl"
            :class="isMobile ? 'f24 b20' : 'f40 b40'"
          >
            <div
              v-vue-aos.once="{animationClass:'fadeInUp animated'}"
              data-aos="fade-left"
              class="bd bb bd-fff pb10 mb10 dib"
            >
              {{ item.t }}
            </div>
            <div
              v-vue-aos.once="{animationClass:'fadeInUp animated'}"
              data-aos="fade-right"
              :class="isMobile ? 'f16' : 'f24'"
            >
              {{ item.d }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--    产品展播    -->
    <div
      class="tc bg-fff"
      :class="isMobile ? '' : 'pb40'"
    >
      <div
        class="relative z1"
        :style="isMobile ? 'font-size:24px;padding: 30px 0 0px 0;' : 'font-size:40px;padding: 80px 0 10px 0;'"
        v-vue-aos.once="{animationClass:'fadeInUp animated'}"
        data-aos="fade-up"
      >
        {{ page[4].t1 }}
      </div>
      <div
        class="dib relative z1"
        style="width: 30px;height: 2px;background: #ed7989;"
      />
      <div
        class="ptb50"
        style="border-radius: 8px"
        :class="isMobile && 'plr20'"
        v-vue-aos.once="{animationClass:'fadeInUp animated'}"
        data-aos="fade-up"
      >
        <VideoPlayer
          v-show="true"
          :videoUrl="page[4].video"
          :videoCover="page[4].img"
          :width="isMobile ? '100%' : '1000'"
          :height="isMobile ? 'auto' : '650'"
          :autoplay="false"
          :controls="true"
          :loop="false"
          :muted="false"
          preload="auto"
          :showPlay="true"
          :playWidth="96"
          zoom="cotain"
        />
      </div>
    </div>

    <!--    企业资质    -->
    <div
      class="tc pb40 bg-fff"
    >
      <div
        class="relative z1"
        :style="isMobile ? 'font-size:24px;padding: 30px 0 0px 0;' : 'font-size:40px;padding: 80px 0 10px 0;'"
        v-vue-aos.once="{animationClass:'fadeInUp animated'}"
        data-aos="fade-up"
      >
        {{ page[5].t1 }}
      </div>

<!--      <div style="color: #aaa;"-->
<!--           v-vue-aos.once="{animationClass:'fadeInUp animated'}"-->
<!--           :style="isMobile ? 'font-size: 12px; margin-top: 2px; ' : 'font-size: 13px; margin-top: -5px; '"-->
<!--      >{{ page[5].tips }}</div>-->

      <div
        class="dib relative z1"
        style="width: 30px;height: 2px;background: #ed7989;"
      />


<!--      <div style="margin-top: 25px;">-->
<!--        <img-->
<!--          :src="page[5].huiyuandw"-->
<!--          alt=""-->
<!--          style="width: 50%;"-->
<!--        >-->
<!--      </div>-->

      <div
        class=""
        :class="isMobile ? 'pt50' : 'flex-center ptb50'"
      >
        <div
          v-for="(item, i) in page[5].d"
          :key="i"
          class="flex-center mlr10 br10 p20"
          :class="isMobile ? 'mb20' : ''"
          style="background:#EAF2FA"
          v-vue-aos.once="{animationClass:'fadeInUp animated'}"
          data-aos="flip-left"
          data-aos-duration="1000"
        >
          <div
            class="f20 tl"
            style="margin-right:87px;width:180px;"
          >
            {{ item.t }}
          </div>
          <img
            :src="item.img"
            alt=""
          >
        </div>
      </div>
    </div>

    <!--    企业动态    -->
    <div
      class="tc pb40 bg-fff"
    >
      <div
        class="relative z1"
        :style="isMobile ? 'font-size:24px;padding: 30px 0 0px 0;' : 'font-size:40px;padding: 80px 0 10px 0;'"
        v-vue-aos.once="{animationClass:'fadeInUp animated'}"
        data-aos="fade-up"
      >
        {{ page[6].t1 }}
      </div>
      <div
        class="dib relative z1"
        style="width: 30px;height: 2px;background: #ed7989;"
      />
      <div class="ptb50 plr20 oa touch flex aaaaa">
        <div
          v-for="item in page[6].d"
          :key="item"
          class="swiper-slide-page6 mlr10 flex-shrink-0"
        >
          <img
            style="border-radius: 5px;"
            :src="item"
            class="width-100"
          >
        </div>
      </div>
    </div>

    <!--    发展历程    -->
    <div
      class="tc pb40"

      v-vue-aos.once="{animationClass:'fadeInUp animated'}"
      data-aos="fade-up"
    >
      <div
        class="relative z1"
        :style="isMobile ? 'font-size:24px;padding: 30px 0 0px 0;' : 'font-size:40px;padding: 80px 0 10px 0;'"
      >
        {{ page[3].t1 }}
      </div>
      <!--      <div style="color: #aaa;"-->
      <!--           :style="isMobile ? 'font-size: 12px; margin-top: 2px; ' : 'font-size: 13px; margin-top: -5px; '"-->
      <!--      >{{ page[3].tips }}</div>-->
      <div
        class="dib relative z1"
        style="width: 30px;height: 2px;background: #ed7989; margin-top:18px;"
      />
      <div
        v-if="!isMobile"
        class="relative"
        v-vue-aos.once="{animationClass:'fadeInUp animated'}"
        data-aos="fade-up"
        data-aos-duration="2000"
      >
        <img
          :src="page[3].img"
          alt=""
          style="width: 70%; margin-top: 50px; border-radius: 5px;"
          class=""
        >
      </div>
      <div
        v-if="isMobile"
        class="oa"
      >
        <div class="relative">
          <div
            class="absolute b0 t0 bg-eee br4"
            style="width:10px;left:45px;"
          />
          <div
            v-for="(item,i) in page[3].d.slice().reverse()"
            :key="i"
            class="flex  pl40 mt40 relative z1"
          >
            <img
              :src="site.icon.dot"
              width="20px"
              height="20px"
              alt=""
              style="border-radius:50%; margin-top: 6px;"
              v-vue-aos.once="{animationClass:'fadeInUp animated'}"
              data-aos="fade-up"
            >
            <div class="tl ml30"
                 v-vue-aos.once="{animationClass:'fadeInUp animated'}"
                 data-aos="fade-up"
                 data-aos-duration="2000"
            >
              <div
                class="f18"
                style="color:#ed7989; font-weight: bold;"
              >
                {{ item.t }} - <span class="c-333" style="font-weight: normal;"> {{ item.d }}</span>
              </div>
              <div class="img">
                <img
                  :src="item.img"
                  width="80%"
                  alt=""
                  style="border-radius:3px; margin-top: 8px;"
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import VideoPlayer from '@/components/VideoPlayer'

export default {
  data() {
    return {
      page: [],
    }
  },

  components: {
    VideoPlayer
  },

  async created () {
    const { data } = await this.fetch('/zoujin.json');
    // console.log(data)
    this.page = data;
  },

  methods: {
    onAnimationstart() {
      this.$refs.count.forEach(vm => vm.start())
    }
  }
}
</script>

<style lang="less" scoped>
.swiper-slide-page6 {
  width:340px!important;
  height:225px;
}
.aaaaa::-webkit-scrollbar {
  width:0;
}
</style>
